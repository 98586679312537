import React, { useState } from 'react';
import { Link } from 'gatsby';
import Switch from 'react-switch';
import styled from 'styled-components';
import media from '../utils/media';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

// Tell Font Awesome to skip adding the CSS automatically since it's being
// imported above.
config.autoAddCss = false;

const Header = styled.header`
  width: 720px;
  max-width: 720px;
  margin: 0 auto;

  ${media.tablet`
    width: 80%;
  `}

  ${media.phone`
    width: 96%;
  `}
`;

const Layout = ({ children }) => {
  const [theme, setTheme] = useState('dark');

  function toggleTheme(checked) {
    setTheme(checked ? 'light' : 'dark');
  }

  return (
    <div className={`${theme} container`}>
      <Header
        style={{
          display: `flex`,
          flexDirection: `row`,
          justifyContent: `space-between`,
          alignItems: `center`,
        }}
      >
        <h1 style={{ display: `flex`, flex: 1 }}>
          <Link to="/">BROG</Link>
        </h1>
        <Switch
          label="checkbox"
          role="checkbox"
          aria-label="Switch between light and dark mode."
          checked={theme === 'light'}
          onChange={toggleTheme}
          width={58}
          offColor="#000"
          uncheckedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <FontAwesomeIcon color="yellow" size="xs" icon={faMoon} />
            </div>
          }
          onColor="#333"
          checkedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <FontAwesomeIcon color="#ffa500" size="sm" icon={faSun} />
            </div>
          }
        />
      </Header>
      {children}
    </div>
  );
};

export default Layout;
